import React, { useLayoutEffect, useMemo, useState } from "react";
import Spinner from "react-spinkit";
import { Form, Formik } from "formik";
import { useParams } from "react-router-dom";
import ArrowScrollTop from "../components/ArrowScrollTop";
import Footer from "../components/Footer";
import HeaderMain from "../components/HeaderMain";
import PageWrapper from "../components/PageWrapper";
import VirtualStagingSlider from "../components/VirtualStagingSlider";
import * as routes from "../constants/routes";
import arrowLeft from "../assets/img/icons/icon-arrow-left.svg";
import { doGetVirtualStagingRefById } from "../firebase/db";
import { getCurrentUser } from "../firebase/auth";
import SelectField from "../components/FormComponents/SelectField";
import iconPlus from "../assets/img/icons/icon-plus-white.svg";
import { finishedStatuses, inProgressStatuses, roomStyleOptions, roomTypeOptions } from "../constants/virtualStaging";
import { getHeaders } from "../utils/api";
import { baseURL } from "../constants/api";
import Badge from "../components/Badge";

const createVariants = async payload => {
  try {
    const headers = await getHeaders();
    const response = await (
      await fetch(`${baseURL}create-variant`, {
        method: "POST",
        headers,
        body: JSON.stringify(payload),
      })
    ).json();

    return response;
  } catch (error) {
    return null;
  }
};

const VirtualStagingVariants = () => {
  const currentUser = getCurrentUser();
  const [virtualStaging, setVirtualStaging] = useState();
  const { renderId } = useParams();
  const [selectedImage, setSelectedImage] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useLayoutEffect(() => {
    const ref = doGetVirtualStagingRefById(currentUser?.uid, renderId);
    const listener = ref.on("value", snapshot => {
      setVirtualStaging(snapshot.val());
    });

    return () => ref.off("value", listener);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [renderId, currentUser?.uid]);

  const variants = useMemo(() => {
    if (virtualStaging?.render?.outputs) {
      const sliderImages = Object.values(virtualStaging.render.outputs).map(({ internalLink, name, format }) => ({
        id: name,
        url: internalLink,
        format: format,
        name: name,
      }));

      if (!selectedImage) {
        setSelectedImage(sliderImages[0]);
      }

      return sliderImages;
    }

    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [virtualStaging]);

  const handleFormSubmit = async values => {
    setIsLoading(true);
    try {
      await createVariants({
        style: values.style.value,
        room_type: values.room_type.value,
        wait_for_completion: false,
        render_id: virtualStaging.render_id,
        virtualStagingId: virtualStaging.id,
      });
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <HeaderMain />
      <PageWrapper className="virtual-staging">
        <div className="edit-tour-header">
          <div className="page-back-link">
            <a href={routes.VIRTUAL_STAGING}>
              <img src={arrowLeft} alt="" />
              <span>BACK TO VIRTUAL STAGING</span>
            </a>
          </div>
        </div>
        <div className="title">Virtual Staging variants</div>
        {virtualStaging ? (
          <div className="variants-wrapper">
            <div className="variants-left">
              <div className="original-image-title">Original</div>
              <div
                className="original-image"
                style={{
                  backgroundImage: `url(${virtualStaging.source_image})`,
                }}
              />
              <Formik
                onSubmit={handleFormSubmit}
                initialValues={{
                  renderFurniture: false,
                  removeFurniture: false,
                  room_type:
                    roomTypeOptions.find(({ value }) => value === virtualStaging.room_type) || roomTypeOptions[0],
                  style: roomStyleOptions.find(({ value }) => value === virtualStaging.style) || roomStyleOptions[0],
                }}
              >
                {() => (
                  <Form>
                    <div className="selects-container">
                      <SelectField name="room_type" label="Room type" options={roomTypeOptions} />
                      <SelectField name="style" label="Furniture style" options={roomStyleOptions} />
                    </div>
                    <div className="submit-wrapper">
                      <button
                        className="custom_button__light  free-width-button  custom_button__icon"
                        type="submit"
                        disabled={
                          isLoading || !virtualStaging || inProgressStatuses.includes(virtualStaging.render.status)
                        }
                      >
                        <img src={iconPlus} alt="" />
                        <span>Create another variation</span>
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
            <div className="slider-wrapper">
              {variants.length ? (
                <div
                  className="slider-selected-image"
                  style={{
                    backgroundImage: `url(${selectedImage?.url})`,
                  }}
                >
                  <Badge
                    text="Download"
                    file={selectedImage}
                    style={{
                      right: "10px",
                      height: "24px",
                    }}
                  />
                </div>
              ) : (
                <Spinner name="three-bounce" className="slider-selected-image" />
              )}

              <VirtualStagingSlider
                images={
                  finishedStatuses.includes(virtualStaging.render.status)
                    ? variants
                    : [...variants, { id: "render", url: virtualStaging.source_image }]
                }
                onPress={image => setSelectedImage(image)}
                active={selectedImage?.id}
              />
            </div>
          </div>
        ) : (
          <div className="spinner-wrapper">
            <Spinner name="three-bounce" />
          </div>
        )}
      </PageWrapper>
      <Footer />
      <div className="tour-scroll-top">
        <ArrowScrollTop onlyIcon desktopVisible />
      </div>
    </>
  );
};

export default VirtualStagingVariants;
