import React, { useContext } from "react";
import { Router, Route, Switch, Redirect, useHistory } from "react-router-dom";

import Tour from "../pages/Tour";

import SignIn from "../pages/SignIn";
import SignUp from "../pages/SignUp";
import PasswordForget from "../pages/PasswordForget";
import PasswordUpdate from "../pages/PasswordUpdate";
import EmailUpdate from "../pages/EmailUpdate";
import Account from "../pages/Account";

import Tours from "../pages/Tours";
import DeleteAccount from "../pages/DeleteAccount";

import withAuthentication, { AuthUserContext } from "../contextProviders/AuthContextProvider";
import * as routes from "../constants/routes";
import NeedEmailVerification from "../pages/NeedEmailVerification";
import CompleteSignUp from "../pages/CompleteSignUp";
import Landing from "../pages/Landing";
import TourCreate from "../pages/TourCreate";
import TourEdit from "../pages/TourEdit";
import Action from "../components/Action";
import ResetLinkSent from "../pages/ResetLinkSent";
import TourNotFound from "../pages/TourNotFound";
import Page404 from "../pages/404";

import AccountMobileInfo from "../pages/SettingsMobile/AccountMobileInfo";
import AccountMobileLogo from "../pages/SettingsMobile/AccountMobileLogo";
import AccountMobileChangePassword from "../pages/SettingsMobile/AccountMobileChangePassword";
import AccountMobileDeactivation from "../pages/SettingsMobile/AccountMobileDeactivation";
import AgentMobileInfo from "../pages/SettingsMobile/AgentMobileInfo";
import SubscriptionMobileCancel from "../pages/SettingsMobile/SubscriptionMobileCancel";
import SubscriptionMobilePaymentMethod from "../pages/SettingsMobile/SubscriptionMobilePaymentMethod";
import SubscriptionMobileInvoices from "../pages/SettingsMobile/SubscriptionMobileInvoices";
import SubscriptionMobileLevel from "../pages/SettingsMobile/SubscriptionMobileLevel";
import Cameras from "../pages/Cameras";
import CommunityTours from "../pages/CommunityTours";
import VirtualStaging from "../pages/VirtualStaging";
import VirtualStagingUpload from "../pages/VirtualStagingUpload";
import VirtualStagingVariants from "../pages/VirutalStagingVariants";

const needsEmailVerification = authUser =>
  authUser &&
  !authUser.emailVerified &&
  authUser.providerData.map(provider => provider.providerId).includes("password");

const Routes = () => {
  const history = useHistory();
  const { isAuthChecked, authUser } = useContext(AuthUserContext);

  if (!isAuthChecked) {
    return null;
  }

  const isAuthorized = authUser;
  const isVerifided = !needsEmailVerification(authUser);

  const renderRoutesForNotVerifiedUser = () => {
    return (
      <Switch>
        <Route exact path={routes.EMAIL_VERIFICATION} component={NeedEmailVerification} />
        <Route exact path={routes.COMPLETE_SIGN_UP} component={CompleteSignUp} />
        <Route exact path={routes.ACTION} component={Action} />
        <Redirect to={routes.EMAIL_VERIFICATION} />
        <Redirect to={routes.PAGE_NOT_FOUND} />
      </Switch>
    );
  };

  const renderRoutesForNotAuthUser = () => {
    return (
      <Switch>
        <Route exact path={routes.LANDING} component={Landing} />
        <Route exact path={routes.CAMERAS} component={Cameras} />
        <Route exact path={routes.COMMUNITY_TOURS} component={CommunityTours} />
        <Route exact path={routes.SIGN_IN} component={SignIn} />
        <Route exact path={routes.SIGN_UP} component={SignUp} />
        <Route exact path={routes.PASSWORD_FORGET} component={PasswordForget} />
        <Route exact path={routes.ACTION} component={Action} />
        <Route exact path={routes.RESET_LINK_SENT} component={ResetLinkSent} />
        <Route exact path={routes.GALLERY} component={Tour} />
        <Redirect to={routes.LANDING} />
      </Switch>
    );
  };

  const renderRoutesForAuthUser = () => {
    return (
      <Switch>
        <Route exact path={routes.EMAIL_VERIFICATION} component={NeedEmailVerification} />
        <Route exact path={routes.COMPLETE_SIGN_UP} component={CompleteSignUp} />
        <Route exact path={routes.ACTION} component={Action} />
        <Route exact path={routes.EMAIL_VERIFICATION} component={NeedEmailVerification} />
        <Route exact path={routes.COMPLETE_SIGN_UP} component={CompleteSignUp} />
        <Route exact path={routes.ACTION} component={Action} />
        <Route exact path={routes.LANDING} component={Landing} />
        <Route exact path={routes.CAMERAS} component={Cameras} />
        <Route exact path={routes.COMMUNITY_TOURS} component={CommunityTours} />
        <Route exact path={routes.ACCOUNT} component={Account} />
        <Route exact path={routes.ACCOUNT_INFO} component={AccountMobileInfo} />
        <Route exact path={routes.ACCOUNT_COMPANY_LOGO} component={AccountMobileLogo} />
        <Route exact path={routes.ACCOUNT_CHANGE_PASSWORD} component={AccountMobileChangePassword} />
        <Route exact path={routes.ACCOUNT_DEACTIVATION} component={AccountMobileDeactivation} />
        <Route exact path={routes.AGENT_INFO} component={AgentMobileInfo} />
        <Route exact path={routes.SUBSCRIPTION_LEVEL} component={SubscriptionMobileLevel} />
        <Route exact path={routes.SUBSCRIPTION_PAYMENT_METHOD} component={SubscriptionMobilePaymentMethod} />
        <Route exact path={routes.SUBSCRIPTION_INVOICES} component={SubscriptionMobileInvoices} />
        <Route exact path={routes.SUBSCRIPTION_CANCEL} component={SubscriptionMobileCancel} />
        <Route exact path={routes.TOURS} component={Tours} />

        <Route
          path={routes.VIRTUAL_STAGING}
          component={({ match }) => {
            return (
              <Switch>
                <Route
                  exact
                  path={`${match.path}${routes.VIRTUAL_STAGING_VARIANTS}`}
                  component={VirtualStagingVariants}
                />
                <Route exact path={routes.VIRTUAL_STAGING_UPLOAD} component={VirtualStagingUpload} />
                <Route exact path={routes.VIRTUAL_STAGING_UPLOAD_WITH_ID} component={VirtualStagingUpload} />
                <Route
                  exact
                  path={`${match.path}${routes.VIRTUAL_STAGING_VARIANTS}`}
                  component={VirtualStagingUpload}
                />
                <Route exact path={match.path} component={VirtualStaging} />
                <Redirect to={match.path} />
              </Switch>
            );
          }}
        />
        <Route exact path={routes.PASSWORD_UPDATE} component={PasswordUpdate} />
        <Route exact path={routes.EMAIL_UPDATE} component={EmailUpdate} />
        <Route exact path={routes.DELETE_ACCOUNT} component={DeleteAccount} />
        <Route exact path={routes.MOBILE_SEARCH} component={DeleteAccount} />
        <Route exact path={routes.ACTION} component={Action} />
        <Route exact path={routes.GALLERY} component={Tour} />
        <Route exact path={routes.TOUR_NOT_FOUND} component={TourNotFound} />
        <Route exact path={routes.PAGE_NOT_FOUND} component={Page404} />
        <Route path={routes.TOUR_CREATE} component={TourCreate} />
        <Route path={routes.TOUR_EDIT} component={TourEdit} />
        <Redirect to={routes.LANDING} />
      </Switch>
    );
  };

  const switchRender = () => {
    if (isAuthorized && isVerifided) {
      return renderRoutesForAuthUser();
    }
    if (isAuthorized && !isVerifided) {
      return renderRoutesForNotVerifiedUser();
    }

    return renderRoutesForNotAuthUser();
  };

  return <Router history={history}>{switchRender()}</Router>;
};

export default withAuthentication(Routes);
