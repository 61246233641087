import React, { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import * as routes from "../constants/routes";

import { AuthUserContext } from "../contextProviders/AuthContextProvider";

import logo from "../assets/img/logo-white.svg";
import iconEmail from "../assets/img/icons/icon-email.svg";
import iconPin from "../assets/img/icons/icon-pin.svg";

export default function Footer() {
  const location = useLocation();
  const currentYear = new Date().getFullYear();

  const { authUser } = useContext(AuthUserContext);

  useEffect(() => {
    const id = location.hash;
    const anchor = id && document.querySelector(id);

    if (anchor) {
      setTimeout(() => {
        anchor.scrollIntoView();
      }, 50);
    }
  }, [location.hash]);

  return (
    <div className="is-footer">
      <div className="is-footer-container">
        <div className="footer-logo-wrapper">
          <img src={logo} alt="Skyward's logo" className="footer-logo" />
        </div>

        <div className="footer-lists">
          <ul className="footer-list">
            <li>ABOUT US</li>
            <li>
              <a href={`${window.location.origin}/#about`}>About us</a>
            </li>
            <li>
              <a href={`${window.location.origin}/#howItWorks`}>Our Product</a>
            </li>
          </ul>

          <ul className="footer-list">
            <li>PRODUCT</li>
            {!authUser && (
              <li>
                <a href={routes.SIGN_IN}>Create Account</a>
              </li>
            )}

            <li>
              <a href={`${window.location.origin}/#pricing`}>Pricing</a>
            </li>
          </ul>

          <ul className="footer-list">
            <li>POLICY</li>
            <li>
              <a href="/">Privacy Policy</a>
            </li>
          </ul>
        </div>

        <ul className="footer-list footer-contacts">
          <li>CONTACT US</li>
          <li>
            <img src={iconEmail} alt="" />
            <a href="mailto:info@skyward360tours.com">info@skyward360tours.com</a>
          </li>
          <li>
            <img src={iconPin} alt="" />
            280 West 115th Street, Front Office,
            <br /> New York, NY 10026
          </li>
        </ul>
      </div>

      <div className="is-copyright">© {currentYear} Skyward Spaces, LLC,. All rights reserved.</div>
    </div>
  );
}
