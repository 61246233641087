export const SIGN_UP = "/signup";
export const SIGN_IN = "/signin";
export const LANDING = "/";
export const HOME = "/home";
export const ACCOUNT = "/account";
export const AGENT = "/account?tab=agentInfo";
export const TOURS = "/tours";
export const TOUR_CREATE = "/tours/create";
export const TOUR_CREATE_IMAGES = "/:tourId/images";
export const TOUR_CREATE_ROOMS = "/:tourId/link-rooms";
export const TOUR_EDIT = "/tours/edit/:tourId";
export const PASSWORD_FORGET = "/pw-forget";
export const PASSWORD_UPDATE = "/pw-update";
export const GALLERY = "/tour/:address/:userId/:tourId/:roomId?";
export const NEW_TOUR = "/NewTour";
export const DELETE_ACCOUNT = "/delete-account";
export const EMAIL_UPDATE = "/email-update";
export const EMAIL_VERIFICATION = "/email-verification";
export const COMPLETE_SIGN_UP = "/complete-sign-up";
export const ACTION = "/action";
export const RESET_LINK_SENT = "/reset-link-sent";
export const PAGE_NOT_FOUND = "/404";
export const TOUR_NOT_FOUND = "/tour-not-found";
export const CAMERAS = "/cameras";
export const COMMUNITY_TOURS = "/community-tours";
export const VIRTUAL_STAGING = "/virtual-staging";
export const VIRTUAL_STAGING_UPLOAD = "/virtual-staging/upload";
export const VIRTUAL_STAGING_UPLOAD_WITH_ID = "/virtual-staging/upload/:renderId";
export const VIRTUAL_STAGING_VARIANTS = "/:renderId/variants";

// mobile routes
export const ACCOUNT_INFO = "/account-information";
export const ACCOUNT_COMPANY_LOGO = "/account-company-logo";
export const ACCOUNT_CHANGE_PASSWORD = "/account-change-password";
export const ACCOUNT_DEACTIVATION = "/account-deactivation";
export const AGENT_INFO = "/agent-information";
export const SUBSCRIPTION_LEVEL = "/subscription-level";
export const SUBSCRIPTION_PAYMENT_METHOD = "/subscription-payment-method";
export const SUBSCRIPTION_INVOICES = "/subscription-invoices";
export const SUBSCRIPTION_CANCEL = "/subscription-cancel";

export const MOBILE_SEARCH = "/mobile-search";
