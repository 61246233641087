import {
  SET_SUBSCRIPTIONS_DATA,
  SET_CURRENT_SUBSCRIPTION_DATA,
  SET_BILLING_INFO_DATA,
  SET_INVOICES_DATA,
  SET_SUBSCRIPTIONS_ERROR,
  SET_CURRENT_SUBSCRIPTION_ERROR,
  SET_BILLING_INFO_ERROR,
  SET_INVOICES_ERROR,
  SET_SUBSCRIPTIONS_STARTED,
  SET_CURRENT_SUBSCRIPTION_STARTED,
  SET_BILLING_INFO_STARTED,
  SET_INVOICES_STARTED,
  UPDATE_USER_PLAN_STARTED,
  UPDATE_USER_PLAN_ERROR,
  UPDATE_USER_PLAN_DATA,
  RESET_USER_PLAN_FIELDS,
  CANCEL_SUBSCRIPTION_STARTED,
  CANCEL_SUBSCRIPTION_ERROR,
  CANCEL_SUBSCRIPTION_DATA,
  RESET_RECURLY_STORE,
} from "../types";
import { subscriptionPlans as freePlans } from "../../constants/subscriptions";

const initialState = {
  subscriptions: [],
  subscriptionsAreLoaded: false,
  subscriptionsErrorText: null,

  currentSubscription: null,
  currentSubscriptionIsLoaded: false,
  currentSubscriptionErrorText: null,

  billingInfo: null,
  billingInfoIsLoaded: false,
  billingInfoErrorText: null,

  invoices: [],
  invoicesAreLoaded: false,
  invoicesErrorText: null,

  updatingPlanIsLoaded: false,
  updatingPlanErrorText: null,
  updatingPlanSuccess: null,

  cancelSubscriptionIsLoaded: false,
  cancelSubscriptionErrorText: null,
};

const checkIsNotEmptyArray = array => {
  return Array.isArray(array) && array.length;
};
const replaceCurrencyInMocks = (mocksPlans, serverPlans) => {
  return mocksPlans.map(plan => {
    const payloadCurrencies = checkIsNotEmptyArray(serverPlans) ? serverPlans[0].currencies : plan.currencies;
    const { currency } = payloadCurrencies[0];

    const currencies = plan.currencies.map(item => ({ ...item, currency }));

    return {
      ...plan,
      currencies,
    };
  });
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case SET_SUBSCRIPTIONS_STARTED:
      return {
        ...state,
        subscriptionsAreLoaded: true,
      };
    case SET_SUBSCRIPTIONS_DATA:
      return {
        ...state,
        subscriptions: [...replaceCurrencyInMocks(freePlans, payload), ...payload],
        subscriptionsAreLoaded: false,
      };
    case SET_SUBSCRIPTIONS_ERROR:
      return {
        ...state,
        subscriptionsErrorText: payload,
        subscriptionsAreLoaded: false,
      };

    case SET_CURRENT_SUBSCRIPTION_STARTED:
      return {
        ...state,
        currentSubscriptionIsLoaded: true,
      };
    case SET_CURRENT_SUBSCRIPTION_DATA:
      return {
        ...state,
        currentSubscription: payload,
        currentSubscriptionIsLoaded: false,
      };
    case SET_CURRENT_SUBSCRIPTION_ERROR:
      return {
        ...state,
        currentSubscriptionErrorText: payload,
        currentSubscriptionIsLoaded: false,
      };

    case SET_BILLING_INFO_STARTED:
      return {
        ...state,
        billingInfoIsLoaded: true,
      };
    case SET_BILLING_INFO_DATA:
      return {
        ...state,
        billingInfo: payload,
        billingInfoIsLoaded: false,
      };
    case SET_BILLING_INFO_ERROR:
      return {
        ...state,
        billingInfoErrorText: payload,
        billingInfoIsLoaded: false,
      };

    case SET_INVOICES_STARTED:
      return {
        ...state,
        invoicesAreLoaded: true,
      };
    case SET_INVOICES_DATA:
      return {
        ...state,
        invoices: payload,
        invoicesAreLoaded: false,
      };
    case SET_INVOICES_ERROR:
      return {
        ...state,
        invoicesErrorText: payload,
        invoicesAreLoaded: false,
      };

    case UPDATE_USER_PLAN_STARTED:
      return {
        ...state,
        updatingPlanIsLoaded: true,
        updatingPlanErrorText: null,
      };

    case UPDATE_USER_PLAN_ERROR:
      return {
        ...state,
        updatingPlanIsLoaded: false,
        updatingPlanErrorText: payload,
        updatingPlanSuccess: false,
      };

    case UPDATE_USER_PLAN_DATA:
      return {
        ...state,
        updatingPlanIsLoaded: false,
        updatingPlanErrorText: null,
        updatingPlanSuccess: true,
        currentSubscription: payload,
      };

    case RESET_USER_PLAN_FIELDS:
      return {
        ...state,
        updatingPlanIsLoaded: false,
        updatingPlanErrorText: null,
        updatingPlanSuccess: null,
      };

    case CANCEL_SUBSCRIPTION_STARTED:
      return {
        ...state,

        cancelSubscriptionIsLoaded: true,
        cancelSubscriptionErrorText: null,
      };

    case CANCEL_SUBSCRIPTION_ERROR:
      return {
        ...state,

        cancelSubscriptionIsLoaded: false,
        cancelSubscriptionErrorText: payload,
      };

    case CANCEL_SUBSCRIPTION_DATA:
      return {
        ...state,
        cancelSubscriptionIsLoaded: false,
        currentSubscription: payload,
      };
    case RESET_RECURLY_STORE:
      return {
        ...initialState,
      };

    default:
      return state;
  }
}
