import React from "react";

import SubscriptionLevel from "./AccountSettings/SubscriptionLevel";
import SubscriptionPaymentMethod from "./AccountSettings/SubscriptionPaymentMethod";
import SubscriptionInvoices from "./AccountSettings/SubscriptionInvoices";
import SubscriptionCancel from "./AccountSettings/SubscriptionCancel";

import iconAlert from "../assets/img/icons/icon-alert-red.svg";
import useSubscriptionType from "../hooks/useSubscriptionType";
import { FREE_SUBSCRIPTION_PLAN } from "../constants/subscriptions";

const SubscriptionView = () => {
  const { type, limite, isCanceled } = useSubscriptionType();

  return (
    <div className="settings-form__wrapper">
      <SubscriptionLevel />

      <SubscriptionPaymentMethod />

      <SubscriptionInvoices />

      <div className="settings-subscription__footer">
        <SubscriptionCancel disabled={type === FREE_SUBSCRIPTION_PLAN.type || isCanceled} />

        {limite && (
          <div className="error">
            <img src={iconAlert} alt="" />
            {`Now you are limited to only ${limite} Active ${limite === 1 ? "Tour" : "Tours"}`}
          </div>
        )}
      </div>
    </div>
  );
};

export default SubscriptionView;
