import React, { useLayoutEffect, useRef, useState } from "react";
import FileUploader from "react-firebase-file-uploader";
import Spinner from "react-spinkit";
import { useHistory, useParams } from "react-router-dom";
import { Form, Formik } from "formik";
import { v4 } from "uuid";
import ArrowScrollTop from "../components/ArrowScrollTop";
import Footer from "../components/Footer";
import HeaderMain from "../components/HeaderMain";
import PageWrapper from "../components/PageWrapper";
import * as routes from "../constants/routes";
import arrowLeft from "../assets/img/icons/icon-arrow-left.svg";
import iconPlus from "../assets/img/icons/icon-plus.svg";
import { storage } from "../firebase/firebase";
import { doCreateVirtualStagingByUser, doGetVirtualStagingById } from "../firebase/db";
import { getCurrentUser } from "../firebase/auth";
import CheckboxField from "../components/FormComponents/CheckboxField";
import SelectField from "../components/FormComponents/SelectField";
import { roomStyleOptions, roomTypeOptions } from "../constants/virtualStaging";
import { getHeaders } from "../utils/api";
import { baseURL } from "../constants/api";
import { callErrorToast } from "../utils/imageUploader";

const createRender = async payload => {
  try {
    const headers = await getHeaders();
    const response = await (
      await fetch(`${baseURL}render`, {
        method: "POST",
        headers,
        body: JSON.stringify(payload),
      })
    ).json();

    return response;
  } catch (error) {
    return null;
  }
};

const ERROR_IMAGE_FORMAT = "File format is not supported.";

const VirtualStagingUpload = () => {
  const currentUser = getCurrentUser();
  const history = useHistory();
  const getStorageVirtualStagingRef = () => storage().ref("virtualStaging");
  const [imageUrl, setImageUrl] = useState();
  const [isRendering, setIsRendering] = useState(false);
  const [virtualStagingId, setVirtualStagingId] = useState();
  const [progress, setProgress] = useState(0);
  const inputRef = useRef();
  const { renderId } = useParams();
  const [isUploading, setIsUploading] = useState(!!renderId);

  const handleUploadStart = (file, task) => {
    if (file.type.includes("image/") && !file.type.includes("gif")) {
      setIsUploading(true);
    } else {
      task.cancel();
      callErrorToast(ERROR_IMAGE_FORMAT);
    }
  };

  const handleProgress = percent => {
    setProgress(percent);
  };

  const handleUploadError = () => {};
  const handleUploadSuccess = async filename => {
    const id = v4();

    try {
      const downloadUrl = await getStorageVirtualStagingRef().child(filename).getDownloadURL();
      await doCreateVirtualStagingByUser(currentUser?.uid, id, {
        source_image_name: filename,
        created_at: Date.now(),
        source_image: downloadUrl,
        id,
        render: {
          status: "done",
        },
      });

      setVirtualStagingId(id);
      setIsUploading(false);
      setImageUrl(downloadUrl);
    } catch (_e) {
      setIsUploading(false);
    }
  };

  const handleFormSubmit = async values => {
    setIsRendering(true);
    await createRender({
      image_url: imageUrl,
      declutter_mode: values.removeFurniture ? "on" : "off",
      add_furniture: values.renderFurniture ? "on" : "off",
      style: values.style.value,
      room_type: values.room_type.value,
      wait_for_completion: false,
      virtualStagingId,
    });

    history.push(routes.VIRTUAL_STAGING);
  };

  const getRender = async () => {
    try {
      setIsUploading(true);
      const value = await doGetVirtualStagingById(currentUser?.uid, renderId);
      setImageUrl(value.source_image);
      setVirtualStagingId(renderId);
    } catch (e) {
    } finally {
      setIsUploading(false);
    }
  };

  useLayoutEffect(() => {
    if (renderId) {
      getRender();

      return;
    }
    setIsUploading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [renderId]);

  return (
    <>
      <HeaderMain />
      <PageWrapper className="virtual-staging">
        <div className="edit-tour-header">
          <div className="page-back-link">
            <a href={routes.VIRTUAL_STAGING}>
              <img src={arrowLeft} alt="" />
              <span>BACK TO VIRTUAL STAGING</span>
            </a>
          </div>
        </div>
        <div className="title">Create Virtual Staging</div>
        {isUploading && (
          <div className="image-loading">
            <Spinner name="three-bounce" />
            <div>{`${progress}%`}</div>
          </div>
        )}

        {imageUrl ? (
          <>
            <div className="image-container">
              <img className="image" src={imageUrl} alt="" />
            </div>
            <Formik
              initialValues={{
                renderFurniture: false,
                removeFurniture: false,
                room_type: roomTypeOptions[0],
                style: roomStyleOptions[0],
              }}
              onSubmit={handleFormSubmit}
            >
              {({ values }) => (
                <Form className="form">
                  <div className="checkbox checkbox-row">
                    <CheckboxField label="Remove existing furniture" name="removeFurniture" />
                    <CheckboxField label="Add furniture" name="renderFurniture" />
                  </div>
                  {values.renderFurniture && (
                    <div className="selects-container">
                      <SelectField name="room_type" label="Room type" options={roomTypeOptions} />
                      <SelectField name="style" label="Furniture style" options={roomStyleOptions} />
                    </div>
                  )}
                  <div className="submit-wrapper">
                    {isRendering ? (
                      <Spinner name="three-bounce" />
                    ) : (
                      <button
                        className="custom_button__light custom_button__md"
                        type="submit"
                        disabled={!(values.removeFurniture || values.renderFurniture)}
                      >
                        <span>Process photo</span>
                      </button>
                    )}
                  </div>
                </Form>
              )}
            </Formik>
          </>
        ) : (
          !isUploading && (
            /* eslint-disable-next-line jsx-a11y/label-has-associated-control */
            <div className="upload-container">
              <label
                className="upload-input-wrapper"
                htmlFor="virtual-staging"
                onDragOver={e => {
                  e.preventDefault();
                }}
                onDrop={e => {
                  e.preventDefault();
                  inputRef.current.startUpload(e.dataTransfer.files[0]);
                }}
                onDragEnter={e => {
                  e.preventDefault();
                }}
              >
                <img src={iconPlus} alt="" />
                Upload or drag image of a room
                <FileUploader
                  ref={inputRef}
                  accept="image/*"
                  name="virtual-staging"
                  id="virtual-staging"
                  type="file"
                  randomizeFilename
                  storageRef={getStorageVirtualStagingRef()}
                  onUploadStart={handleUploadStart}
                  onUploadError={handleUploadError}
                  onUploadSuccess={handleUploadSuccess}
                  onProgress={handleProgress}
                  hidden
                />
              </label>
            </div>
          )
        )}
      </PageWrapper>
      <Footer />
      <div className="tour-scroll-top">
        <ArrowScrollTop onlyIcon desktopVisible />
      </div>
    </>
  );
};

export default VirtualStagingUpload;
