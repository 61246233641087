import React from "react";
import * as PropTypes from "prop-types";

import { useDispatch } from "react-redux";
import * as routes from "../constants/routes";

import { doSignOut } from "../firebase/auth";

import logo from "../assets/img/logo-new.svg";
import alert from "../assets/img/icons/icon-alert.svg";
import close from "../assets/img/icons/icon-close-thin.svg";
import logOut from "../assets/img/icons/icon-logout.svg";
import closeBold from "../assets/img/icons/icon-close-bold.svg";
import { RESET_RECURLY_STORE } from "../redux/types";

const LoginWrapper = ({ title, titleBreak, loginFormWide, error, children, page }) => {
  const dispatch = useDispatch();

  const handleLogoutClick = async () => {
    dispatch({ type: RESET_RECURLY_STORE });
    await doSignOut();
  };

  const renderPageHeader = () => {
    if (page === "verifyEmail") {
      return (
        <button className="like-link" type="button" onClick={handleLogoutClick}>
          <img src={logOut} alt="" />
          Logout
        </button>
      );
    }

    if (page === "resetPassword") {
      return <div />;
    }

    return (
      <a href={routes.LANDING} className="close-login">
        <img src={closeBold} alt="" className="close-login__mobile" />
        <img src={close} alt="" className="close-login__laptop" />
      </a>
    );
  };
  const renderPageFooter = () => {
    if (page === "signIn") {
      return (
        <p>
          Not a member yet? <a href={routes.SIGN_UP}>Create Account now</a>
        </p>
      );
    }

    if (page === "restorePassword" || page === "verifyEmail" || page === "resetPassword" || page === "emailUpdate") {
      return <p />;
    }

    return (
      <p>
        Do you have an account? <a href={routes.SIGN_IN}>Log in</a>
      </p>
    );
  };

  return (
    <div className="login-wrapper">
      <div className="login-image">
        <a href={routes.LANDING} className="login-logo">
          <img className="login-wrapper__logo" src={logo} alt="" />
        </a>
      </div>

      <div className="login-main">
        <div className="login-main-header">
          <a href={routes.LANDING} className="logo-login">
            <img src={logo} alt="" />
          </a>

          {renderPageHeader()}
        </div>

        <div>
          <h2 className={`login-title ${titleBreak ? "login-title-break" : ""}`}>{title}</h2>
          <div className={`login-form ${loginFormWide ? "login-form-wide" : ""}`}>
            <div className={`login-error-wrapper ${error ? "is-active" : ""}`}>
              {error && (
                <div className="login-error">
                  <img src={alert} alt="" />
                  <p>{error}</p>
                </div>
              )}
            </div>
            {children}
          </div>
        </div>

        <div className="login-main-footer">{renderPageFooter()}</div>
      </div>
    </div>
  );
};

LoginWrapper.propTypes = {
  title: PropTypes.string.isRequired,
  titleBreak: PropTypes.bool,
  loginFormWide: PropTypes.bool,
  error: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  page: PropTypes.string,
};

LoginWrapper.defaultProps = {
  titleBreak: undefined,
  loginFormWide: undefined,
  error: undefined,
  children: undefined,
  page: undefined,
};

export default LoginWrapper;
