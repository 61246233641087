import { useEffect, useMemo, useState } from "react";
import {
  FREE_SUBSCRIPTION_PLAN,
  LITE_SUBSCRIPTION_PLAN,
  UNLIMITED_SUBSCRIPTION_PLAN,
} from "../constants/subscriptions";
import { getCurrentUser } from "../firebase/auth";
import { doGetToursByUserSubscription } from "../firebase/db";
import useSubscriptionType from "./useSubscriptionType";

const useCanTourUpdate = () => {
  const user = getCurrentUser();
  const { type } = useSubscriptionType();
  const [activeToursLength, setActiveToursLength] = useState();

  const canAddActiveTour = useMemo(() => {
    if (type === UNLIMITED_SUBSCRIPTION_PLAN.type) {
      return true;
    }
    if (type === LITE_SUBSCRIPTION_PLAN.type && activeToursLength < LITE_SUBSCRIPTION_PLAN.limit) {
      return true;
    }
    if (type === FREE_SUBSCRIPTION_PLAN.type && activeToursLength < FREE_SUBSCRIPTION_PLAN.limit) {
      return true;
    }

    return false;
  }, [type, activeToursLength]);

  const userId = user?.uid;

  useEffect(() => {
    const ref = doGetToursByUserSubscription(userId, {});
    const listener = ref.on("value", values => {
      setActiveToursLength(values.numChildren());
    });

    return () => ref.off("value", listener);
  }, [userId]);

  return { canAddActiveTour };
};

export default useCanTourUpdate;
