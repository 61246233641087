import qs from "qs";
import { v4 } from "uuid";

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "react-js-pagination";
import { useHistory, useLocation } from "react-router-dom";

import {
  doUpdateToursByUser,
  doGetAgent,
  doGetToursByUserSubscription,
  doGetArchivedToursByUserSubscription,
  doArchiveTours,
} from "../firebase/db";
import { SET_TOURS_DATA } from "../redux/types";
import HeaderMain from "../components/HeaderMain";
import PageWrapper from "../components/PageWrapper";
import TourCard from "../components/TourCard";
import SearchBar from "../components/SearchBar";
import { TOURS_COUNT_PER_PAGE } from "../constants/pagination";
import { FILTER_PARAMS, SORT_PARAMS } from "../constants/SortFilterParams";
import { ASCSortFunction, DESCSortFunction, moveElement } from "../utils";
import { MIN_VALUE, MAX_VALUE } from "../constants/filter";
import * as routes from "../constants/routes";
import { getCurrentUser } from "../firebase/auth";
import CheckMobile from "../hooks/checkMobile";

import Footer from "../components/Footer";
import SortToursSelect from "../components/SortToursSelect";
import RangeFilter, { useRangeFilter } from "../components/RangeFilter";
import ArrowScrollTop from "../components/ArrowScrollTop";
import AlertTourWithoutAgentInfo from "../components/Alerts/AlertTourWithoutAgentInfo";
import MinMaxFilter, { useMinMaxFilter } from "../components/MinMaxFilter";
import SearchFilterMobile from "../components/SearchFilterMobile";

import iconPlus from "../assets/img/icons/icon-plus-white.svg";
import iconList from "../assets/img/icons/icon-list.svg";
import iconGrid from "../assets/img/icons/icon-grid.svg";
import iconFilter from "../assets/img/icons/icon-filter.svg";

import iconArrowFirstPagination from "../assets/img/icons/pagination/pagination-first-page.svg";
import iconArrowPrevPagination from "../assets/img/icons/pagination/pagination-prev-page.svg";
import iconArrowNextPagination from "../assets/img/icons/pagination/pagination-next-page.svg";
import iconArrowLastPagination from "../assets/img/icons/pagination/pagination-last-page.svg";
import { loadSubscriptions } from "../redux/actions/recurlyActions";
import ModalNumberToursLimited from "../components/modals/ModalNumberToursLimited";
import useSubscriptionType from "../hooks/useSubscriptionType";
import {
  FREE_SUBSCRIPTION_PLAN,
  LITE_SUBSCRIPTION_PLAN,
  UNLIMITED_SUBSCRIPTION_PLAN,
} from "../constants/subscriptions";
import useCanTourUpdate from "../hooks/useCanTourUpdate";

const renderFilledItems = () => {
  const width = window.innerWidth;

  if (width <= 767) {
    return 4;
  }
  if (width <= 1280) {
    return 9;
  }

  return 16;
};

const filledSceletonTours = new Array(renderFilledItems())
  .fill(true)
  .map(() => (
    <TourCard
      key={v4()}
      tourId=""
      name=""
      imageUrl=""
      bathroomCount={0}
      bedroomCount={0}
      area={0}
      created={0}
      daysInterval={1}
      changeTourOrder={() => {}}
      isLoading
      tourView="grid"
      ownerId=""
      totalViews={0}
    />
  ));

const Tours = () => {
  const dispatch = useDispatch();
  const { type: subscriptionType } = useSubscriptionType();
  const { canAddActiveTour } = useCanTourUpdate();
  const currentSubscriptionIsLoaded = useSelector(state => state.recurly.currentSubscriptionIsLoaded);
  const [error, setError] = useState("");
  const [allPreviews, setAllPreviews] = useState();
  const [previews, setPreviews] = useState([]);
  const [visiblePreviews, setVisiblePreviews] = useState([]);
  const [visibleLimitedAlert, setVisibleLimitedAlert] = useState(false);
  const [activePage, setActivePage] = useState();
  const [tourView, setTourView] = useState("grid");
  const [currentAgent, setCurrentAgent] = useState();

  const [activeTours, setActiveTours] = useState();
  const [archivedTours, setArchivedTours] = useState();
  const [communityTours, setCommunityTours] = useState();

  const history = useHistory();
  const location = useLocation();
  const isMobile = CheckMobile();

  const search = new URLSearchParams(location.search);
  const tab = search.get("tab");
  const [activeTab, setActiveTab] = useState(tab || "active");

  useEffect(() => {
    loadSubscriptions(dispatch);
  }, [dispatch]);

  useEffect(() => {
    if (tab && tab !== activeTab) {
      setActiveTab(tab);
    }
  }, [tab, activeTab]);

  const handleTabClick = clickedTab => {
    search.set("tab", clickedTab);
    search.delete("page");

    history.push({ pathname: location.pathname, search: search.toString() });
  };

  const {
    minCount: minCountBedroom,
    setMinCount: setMinCountBedroom,
    maxCount: maxCountBedroom,
    setMaxCount: setMaxCountBedroom,
  } = useMinMaxFilter(1, 1, FILTER_PARAMS.BEDROOMS);

  const {
    minCount: minCountBathroom,
    setMinCount: setMinCountBathroom,
    maxCount: maxCountBathroom,
    setMaxCount: setMaxCountBathroom,
  } = useMinMaxFilter(1, 1, FILTER_PARAMS.BATHROOM);

  const { currentValues: currentValuesSquare, setCurrentValues: setCurrentValuesSquare } = useRangeFilter(
    MIN_VALUE,
    MAX_VALUE,
    FILTER_PARAMS.SQUARE
  );
  const tours = useSelector(state => state.data.tours);

  const [query, setQuery] = useState(location.search);

  const currentUser = getCurrentUser();

  useEffect(() => {
    let ref;
    let listener;
    if (subscriptionType) {
      ref = doGetToursByUserSubscription("public", {});
      listener = ref.on("value", setCommunityTours);
    }

    return () => (listener ? ref.off("value", listener) : null);
  }, [currentUser.uid, subscriptionType]);

  useEffect(() => {
    let ref;
    let listener;
    if (subscriptionType) {
      ref = doGetArchivedToursByUserSubscription(currentUser.uid, {});
      listener = ref.on("value", setArchivedTours);
    }

    return () => (listener ? ref.off("value", listener) : null);
  }, [currentUser.uid, subscriptionType]);

  useEffect(() => {
    let ref;
    let listener;
    if (subscriptionType) {
      ref = doGetToursByUserSubscription(currentUser.uid, {});
      listener = ref.on("value", values => {
        if (subscriptionType === FREE_SUBSCRIPTION_PLAN.type && values.numChildren() > FREE_SUBSCRIPTION_PLAN.limit) {
          doArchiveTours(currentUser.uid, FREE_SUBSCRIPTION_PLAN.limit);
        } else if (
          subscriptionType === LITE_SUBSCRIPTION_PLAN.type &&
          values.numChildren() > LITE_SUBSCRIPTION_PLAN.limit
        ) {
          doArchiveTours(currentUser.uid, LITE_SUBSCRIPTION_PLAN.limit);
        } else if (subscriptionType === UNLIMITED_SUBSCRIPTION_PLAN.type) {
          setActiveTours(values);
        } else {
          setActiveTours(values);
        }
      });
    }

    return () => (listener ? ref.off("value", listener) : null);
  }, [currentUser.uid, subscriptionType]);

  const getSearchParams = locationSearch => qs.parse(locationSearch, { ignoreQueryPrefix: true });
  const getStringifiedValue = (locationSearch, newParams) =>
    qs.stringify({ ...locationSearch, ...newParams }, { addQueryPrefix: true });

  const setTourViewMode = useCallback(
    view => () => {
      history.push({ search: getStringifiedValue(getSearchParams(location.search), { view }) });
    },
    [history, location.search]
  );

  useEffect(() => {
    const view = search.get("view");

    if (view) {
      setTourView(view);
    }
  }, [search, setTourView]);

  const setToursData = useCallback(
    values => {
      dispatch({ type: SET_TOURS_DATA, payload: values });
      const prev = Object.keys(values || {}).map(item => {
        const data = values[item];

        const {
          name,
          bedroom_count,
          bathroom_count,
          area,
          created_at,
          views,
          totalViews,
          index,
          price,
          ownerId,
        } = data;

        const sortedImages = Object.values(data?.images ?? {}).sort((a, b) => a.index - b.index);

        const imageUrl = sortedImages[0]?.thumbnail_url;
        const imagesUrls = sortedImages.map(sortedImage => sortedImage?.thumbnail_url).slice(0, 2);

        return {
          tourId: item,
          ownerId,
          name,
          imageUrl,
          imagesUrls,
          views,
          totalViews,
          bedroomCount: Number(bedroom_count),
          bathroomCount: Number(bathroom_count),
          area: Number(area),
          price: Number(price),
          created: created_at,
          index,
        };
      });
      setAllPreviews(prev);
    },
    [dispatch]
  );

  useEffect(() => {
    switch (activeTab) {
      case "archived":
        // eslint-disable-next-line no-unused-expressions
        archivedTours && setToursData(archivedTours.val());
        break;
      case "community":
        // eslint-disable-next-line no-unused-expressions
        communityTours && setToursData(communityTours.val());
        break;
      default:
        // eslint-disable-next-line no-unused-expressions
        activeTours && setToursData(activeTours.val());
    }
  }, [activeTab, activeTours, archivedTours, communityTours, setToursData]);

  useEffect(() => {
    async function getAgent() {
      let agent;
      try {
        agent = await doGetAgent(currentUser.uid);
      } catch (err) {
        return err;
      }

      return setCurrentAgent(prevState => ({ ...prevState, ...agent.val() }));
    }
    getAgent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  const getSortedFilteredPreviews = (prevs, params) => {
    let result = prevs;

    switch (params.sort) {
      case SORT_PARAMS.OLDEST:
        result = result.sort(ASCSortFunction("created"));
        break;
      case SORT_PARAMS.MOST_VIEWED:
        result = result.sort(ASCSortFunction("views"));
        break;
      case SORT_PARAMS.LEAST_VIEWED:
        result = result.sort(DESCSortFunction("views"));
        break;
      case SORT_PARAMS.CUSTOM:
        result = result.sort(ASCSortFunction("index"));
        break;
      case SORT_PARAMS.NEWES:
      default:
        result = result.sort(DESCSortFunction("created"));
        break;
    }

    if (params.tab === "community") {
      result = result.filter(value => !(value.ownerId !== currentUser.uid && !value.imagesUrls.length));
    }

    if (params.search) {
      result = result.filter(value => value.name?.toLowerCase().includes(params.search?.toLowerCase()));
    }

    if (params[`filter_${FILTER_PARAMS.BEDROOMS}`]) {
      result = result.filter(
        item =>
          item.bedroomCount >= params[`filter_${FILTER_PARAMS.BEDROOMS}`].min &&
          item.bedroomCount <= params[`filter_${FILTER_PARAMS.BEDROOMS}`].max
      );
    }
    if (params[`filter_${FILTER_PARAMS.BATHROOM}`]) {
      result = result.filter(
        item =>
          item.bathroomCount >= params[`filter_${FILTER_PARAMS.BATHROOM}`].min &&
          item.bathroomCount <= params[`filter_${FILTER_PARAMS.BATHROOM}`].max
      );
    }
    if (params[`filter_${FILTER_PARAMS.SQUARE}`]) {
      result = result.filter(
        item =>
          item.area >= params[`filter_${FILTER_PARAMS.SQUARE}`].min &&
          item.area <= params[`filter_${FILTER_PARAMS.SQUARE}`].max
      );
    }

    return result;
  };

  const setActivePagePreviews = (qParams, prevs) => {
    if (qParams.page) {
      return setVisiblePreviews(
        prevs.slice(TOURS_COUNT_PER_PAGE * (qParams.page - 1), TOURS_COUNT_PER_PAGE * qParams.page)
      );
    }

    return setVisiblePreviews(prevs.slice(0, TOURS_COUNT_PER_PAGE));
  };

  useEffect(() => {
    const searchParams = getSearchParams(location.search);
    const sortedFilteredPreviews = getSortedFilteredPreviews(allPreviews || [], searchParams);
    setPreviews(sortedFilteredPreviews);
    setActivePagePreviews(searchParams, sortedFilteredPreviews);
    setActivePage(searchParams.page ? Number(searchParams.page) : 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search, allPreviews, activePage]);

  const handlePageChange = newPage => {
    history.push({ search: getStringifiedValue(getSearchParams(location.search), { page: newPage }) });
  };

  const handleChangeSortOptions = event => {
    history.push({
      search: getStringifiedValue(getSearchParams(location.search), { sort: event.value, page: 1 }),
    });
  };

  const handleChangeSearchOptions = event => {
    setQuery(getStringifiedValue(getSearchParams(location.search), { search: event.target.value, page: 1 }));
  };

  const handleChangeFilterOptions = useCallback(
    (options, entity) => {
      const stringifiedValue = getStringifiedValue(getSearchParams(query), {
        [`filter_${entity}`]: { ...options },
      });

      setQuery(stringifiedValue);
    },
    [query]
  );

  const handleMultipleChangeFilterOptions = useCallback(
    filters => {
      const filterValue = {};

      // eslint-disable-next-line array-callback-return
      filters.map(({ options, entity }) => {
        filterValue[`filter_${entity}`] = { ...options };
      });

      const stringifiedValue = getStringifiedValue(getSearchParams(query), filterValue);

      setQuery(stringifiedValue);

      return stringifiedValue;
    },
    [query]
  );

  const handleMultipleDeleteFilterOptions = useCallback(
    filters => {
      const searchParams = getSearchParams(query);

      filters.forEach(entity => {
        delete searchParams[entity];
      });

      filters.forEach(entity => {
        delete searchParams[entity];
      });

      const stringifiedQuery = getStringifiedValue(searchParams);

      history.push({ search: stringifiedQuery });
      setQuery(stringifiedQuery);
    },
    [history, query]
  );

  const handleDeleteFilterOptions = useCallback(
    (options, filterEntity) => {
      const searchParams = getSearchParams(query);
      delete searchParams[filterEntity];
      const stringifiedQuery = getStringifiedValue(searchParams);

      history.push({ search: stringifiedQuery });
      setQuery(stringifiedQuery);
    },
    [history, query]
  );

  const handleDeleteSearchFilters = useCallback(() => {
    handleMultipleDeleteFilterOptions([
      "page",
      `filter_${FILTER_PARAMS.BATHROOM}`,
      `filter_${FILTER_PARAMS.BEDROOMS}`,
      `filter_${FILTER_PARAMS.SQUARE}`,
    ]);
  }, [handleMultipleDeleteFilterOptions]);

  const handleSearchWithFilterOptions = useCallback(() => {
    history.push({ search: query, page: 1 });
  }, [history, query]);

  const handleClearSearchOptions = useCallback(() => {
    handleMultipleDeleteFilterOptions(["search", "page"]);
  }, [handleMultipleDeleteFilterOptions]);

  const updateTours = async updatedTours => {
    const newTours = { ...tours };
    updatedTours.forEach(({ tourId }, index) => {
      newTours[tourId].index = index;
    });
    try {
      await doUpdateToursByUser(search.get("tab") === "community" ? "public" : currentUser.uid, newTours);
    } catch (err) {
      return setError(err?.message);
    }

    return null;
  };

  const changeTourOrder = async (sourceId, destinationId) => {
    const sourceIndex = previews.findIndex(prev => prev.tourId === sourceId);
    const destinationIndex = previews.findIndex(prev => prev.tourId === destinationId);

    if (sourceId === -1 || destinationId === -1) {
      return;
    }
    const offset = destinationIndex - sourceIndex;
    const newPreviews = moveElement(previews, sourceIndex, offset);
    setPreviews(newPreviews);
    setActivePagePreviews(getSearchParams(location.search), newPreviews);
    if (getSearchParams(location.search).sort !== SORT_PARAMS.CUSTOM) {
      handleChangeSortOptions({ target: { value: SORT_PARAMS.NEWEST } });
    }
    await updateTours(newPreviews);
  };

  const agentInfoIsAbsent = currentAgent && Object.values(currentAgent).filter(x => x.length > 0).length === 0;

  const [showFilter, setShowFilter] = useState(false);
  const toggleFilter = useCallback(() => setShowFilter(showFilterValue => !showFilterValue), []);

  const onToggleLimitedAlert = () => {
    setVisibleLimitedAlert(false);
  };

  const handleSaveMobileFilter = useCallback(
    (bedroom, bathroom, square) => {
      const newQuery = handleMultipleChangeFilterOptions([
        { options: bedroom, entity: FILTER_PARAMS.BEDROOMS },
        { options: bathroom, entity: FILTER_PARAMS.BATHROOM },
        { options: square, entity: FILTER_PARAMS.SQUARE },
      ]);

      toggleFilter();

      history.push({ search: newQuery, page: 1 });
    },
    [handleMultipleChangeFilterOptions, toggleFilter, history]
  );

  const addNewTourText = useMemo(() => {
    if (currentSubscriptionIsLoaded) {
      return "waiting.....";
    }

    return "Add a new tour";
  }, [currentSubscriptionIsLoaded]);

  const createNewTour = async () => {
    if (canAddActiveTour) {
      history.push(routes.TOUR_CREATE);
    } else {
      setVisibleLimitedAlert(true);
    }
  };

  return (
    <>
      <HeaderMain />

      <PageWrapper>
        <SearchFilterMobile
          isVisible={showFilter}
          toggleFilter={toggleFilter}
          minArea={MIN_VALUE}
          maxArea={MAX_VALUE}
          handleSave={handleSaveMobileFilter}
          handleDelete={handleDeleteSearchFilters}
        />

        {agentInfoIsAbsent && <AlertTourWithoutAgentInfo />}

        <div className="tour-container">
          {error && <p className="error">{error} </p>}
          <div className="search-wrapper">
            <SearchBar
              handleChangeSearchOptions={handleChangeSearchOptions}
              handleClearInput={handleClearSearchOptions}
              inputValue={search.get("search")}
              onEnterPress={handleSearchWithFilterOptions}
            />

            {!isMobile && (
              <>
                <MinMaxFilter
                  title="Bedrooms"
                  placeholder="Add number of bedrooms"
                  min={1}
                  max={4}
                  handleChange={params => handleChangeFilterOptions(params, FILTER_PARAMS.BEDROOMS)}
                  handleClear={params => handleDeleteFilterOptions(params, `filter_${FILTER_PARAMS.BEDROOMS}`)}
                  minCount={minCountBedroom}
                  setMinCount={setMinCountBedroom}
                  maxCount={maxCountBedroom}
                  setMaxCount={setMaxCountBedroom}
                />

                <MinMaxFilter
                  title="Bathroom"
                  placeholder="Add number of bathrooms"
                  min={1}
                  max={5}
                  handleChange={params => handleChangeFilterOptions(params, FILTER_PARAMS.BATHROOM)}
                  handleClear={params => handleDeleteFilterOptions(params, `filter_${FILTER_PARAMS.BATHROOM}`)}
                  minCount={minCountBathroom}
                  setMinCount={setMinCountBathroom}
                  maxCount={maxCountBathroom}
                  setMaxCount={setMaxCountBathroom}
                />

                <RangeFilter
                  title="Square"
                  placeholder="sq ft"
                  min={MIN_VALUE}
                  max={MAX_VALUE}
                  handleChange={params => handleChangeFilterOptions(params, FILTER_PARAMS.SQUARE)}
                  handleClear={params => handleDeleteFilterOptions(params, `filter_${FILTER_PARAMS.SQUARE}`)}
                  currentValues={currentValuesSquare}
                  setCurrentValues={setCurrentValuesSquare}
                />

                <button
                  onClick={handleSearchWithFilterOptions}
                  type="button"
                  className="custom_button__light custom_button-sm-width"
                >
                  Search
                </button>
              </>
            )}
          </div>
          <div className="tours-tabs">
            <button
              type="button"
              onClick={() => handleTabClick("active")}
              className={`tours-tab-button ${activeTab === "active" && "is-active"}`}
            >
              Active
              <span className="tours-word">&nbsp;tours</span>
              <span>&nbsp;({Object.keys(activeTours?.val() || {}).length})</span>
            </button>
            <button
              type="button"
              onClick={() => handleTabClick("archived")}
              className={`tours-tab-button ${activeTab === "archived" && "is-active"}`}
            >
              Archived
              <span className="tours-word">&nbsp;tours</span>
              <span>&nbsp;({Object.keys(archivedTours?.val() || {}).length})</span>
            </button>
            <button
              type="button"
              onClick={() => handleTabClick("community")}
              className={`tours-tab-button ${activeTab === "community" && "is-active"}`}
            >
              Community
              <span className="tours-word">&nbsp;tours</span>
              <span>
                &nbsp;(
                {
                  Object.values(communityTours?.val() || {}).filter(
                    value => !(value.ownerId !== currentUser.uid && !value.images)
                  ).length
                }
                )
              </span>
            </button>
          </div>
          <div className="tours-filter-panel">
            <button
              disabled={currentSubscriptionIsLoaded}
              type="button"
              className="custom_button__light custom_button__icon custom_button__md"
              onClick={createNewTour}
            >
              <img src={iconPlus} alt="" />
              {addNewTourText}
            </button>

            <div className="tours-sort-panel">
              <button onClick={toggleFilter} type="button" className="btn-tours-filter custom_button__only-icon">
                <img src={iconFilter} alt="" />
              </button>

              <SortToursSelect handleChangeSortOptions={handleChangeSortOptions} />

              <button
                onClick={setTourViewMode("grid")}
                type="button"
                className={`custom_button__only-icon ${tourView === "grid" ? "is-active" : ""}`}
              >
                <img src={iconGrid} alt="" />
              </button>
              <button
                onClick={setTourViewMode("list")}
                type="button"
                className={`custom_button__only-icon ${tourView === "list" ? "is-active" : ""}`}
              >
                <img src={iconList} alt="" />
              </button>
            </div>
          </div>

          <div className={`tours-container ${tourView}-tours-view`}>
            {allPreviews
              ? visiblePreviews.map(
                  ({
                    tourId,
                    ownerId,
                    name,
                    imageUrl,
                    imagesUrls,
                    bathroomCount,
                    bedroomCount,
                    area,
                    created,
                    views,
                    totalViews,
                    price,
                  }) => (
                    <TourCard
                      key={`${tourId}_${imageUrl}`}
                      tourId={tourId}
                      ownerId={ownerId}
                      name={name}
                      imageUrl={imageUrl}
                      imagesUrls={imagesUrls}
                      tourView={tourView}
                      bathroomCount={bathroomCount}
                      bedroomCount={bedroomCount}
                      area={Number(area)}
                      price={price}
                      created={created}
                      views={views}
                      totalViews={totalViews}
                      changeTourOrder={changeTourOrder}
                    />
                  )
                )
              : filledSceletonTours}
          </div>

          {previews.length > TOURS_COUNT_PER_PAGE && (
            <div className="tours-pagination">
              <Pagination
                activePage={activePage}
                itemsCountPerPage={TOURS_COUNT_PER_PAGE}
                totalItemsCount={previews.length}
                pageRangeDisplayed={5}
                onChange={handlePageChange}
                linkClass="tours-pagination__link"
                activeLinkClass="tours-pagination__active"
                itemClassFirst="tours-pagination__first"
                firstPageText={<img src={iconArrowFirstPagination} alt="" />}
                itemClassPrev="tours-pagination__prev"
                prevPageText={<img src={iconArrowPrevPagination} alt="" />}
                itemClassNext="tours-pagination__next"
                nextPageText={<img src={iconArrowNextPagination} alt="" />}
                itemClassLast="tours-pagination__last"
                lastPageText={<img src={iconArrowLastPagination} alt="" />}
              />
            </div>
          )}
        </div>
      </PageWrapper>

      <Footer />

      <div className="tour-scroll-top">
        <ArrowScrollTop onlyIcon desktopVisible />
      </div>
      {visibleLimitedAlert && <ModalNumberToursLimited onToggle={onToggleLimitedAlert} />}
    </>
  );
};

export default Tours;
