// eslint-disable-next-line import/prefer-default-export
export const FREE_SUBSCRIPTION_PLAN = {
  type: "free",
  limit: 1,
};
export const LITE_SUBSCRIPTION_PLAN = {
  type: "lite",
  limit: 5,
};
export const UNLIMITED_SUBSCRIPTION_PLAN = {
  type: "unlimited",
  limit: Infinity,
};

export const subscriptionPlans = [
  {
    id: "01",
    type: FREE_SUBSCRIPTION_PLAN.type,
    currencies: [
      {
        unitAmount: 0,
        currency: "USD",
        setupFee: 0,
      },
    ],
    interval: 1,
    options: [
      "1 Active Tour",
      "Mobile App",
      "Share via dedicated URL or embed code",
      "Up to 12K resolution",
      "Tour Analytics",
    ],
  },
  {
    id: "02",
    type: FREE_SUBSCRIPTION_PLAN.type,
    currencies: [
      {
        unitAmount: 0,
        currency: "USD",
        setupFee: 0,
      },
    ],
    interval: 12,
    options: [
      "1 Active Tour",
      "Mobile App",
      "Share via dedicated URL or embed code",
      "Up to 12K resolution",
      "Tour Analytics",
    ],
  },
];
