import React from "react";
import * as PropTypes from "prop-types";

import amazonButton from "../assets/img/amazon-btn.png";

const CameraItem = ({ img, title, linkWebsite, linkAmazon, children }) => {
  return (
    <div className="camera-item">
      <div className="camera-item__image">
        <img src={img} alt={title} />
      </div>

      <div className="camera-item__info">
        <h2 className="camera-item__title">{title}</h2>
        <div className="camera-item__children">{children}</div>
        <div className="camera-item__buttons">
          <a className="custom_button" href={linkWebsite}>
            Visit web site
          </a>
          <a href={linkAmazon}>
            <img src={amazonButton} alt="" />
          </a>
        </div>
      </div>
    </div>
  );
};

CameraItem.propTypes = {
  img: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  linkWebsite: PropTypes.string.isRequired,
  linkAmazon: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

CameraItem.defaultProps = {
  linkAmazon: undefined,
  children: undefined,
};

export default CameraItem;
