import React from "react";
import { v4 } from "uuid";

import HeaderMain from "../components/HeaderMain";
import Footer from "../components/Footer";
import CameraItem from "../components/CameraItem";

import checkMobile from "../hooks/checkMobile";

import appStore from "../assets/img/landing/app-store-button.svg";
import heroMobile from "../assets/img/landing/camers-bg__mobile.png";
import heroDesktop from "../assets/img/landing/camers-bg__desktop.png";

import camera1 from "../assets/img/camera/camera1.jpg";
import camera2 from "../assets/img/camera/camera2.jpg";
import camera3 from "../assets/img/camera/camera3.jpg";
import camera4 from "../assets/img/camera/camera4.jpg";
import camera5 from "../assets/img/camera/camera5.jpg";

import useScrollTopOnHistory from "../hooks/useScrollTopOnHistory";
import { APP_STORE_LINK } from "../constants/shared";

const cameras = [
  {
    img: camera1,
    title: "Ricoh Theta Z1 360 Spherical Camera",
    linkWebsite: "https://theta360.com/en/about/theta/z1.html",
    linkAmazon: "https://www.amazon.com/Theta-degree-Spherical-Camera-Sensors/dp/B07NSQ13CB",
    info: [
      "Still image resolution: 6720x3360",
      "Video resolution/frame rate/bit rate: 4K(3840×1920/29.97fps/56Mbps), 2K(1920×960/29.97fps/16Mbps)",
      "Internal memory: Approx. 19GB",
      "Effective pixels: Approx. 20 megapixels (x2)",
      "Shooting mode: Still image/video (auto, aperture priority, shutter priority, ISO priority, manual) live streaming(auto)",
      "Battery life: Still image - approx. 300 photos, video - approx. 60 minutes",
    ],
  },
  {
    img: camera2,
    title: "Ricoh Theta V 4k 360 Spherical Camera",
    linkWebsite: "https://theta360.com/en/about/theta/v.html",
    linkAmazon: "https://www.amazon.com/Ricoh-Theta-360-Spherical-Camera/dp/B074W5BKYS",
    info: [
      "Still image resolution: 5376×2688",
      "Video resolution/frame rate/bit rate: 4K(H264: 3840×1920/29.97fps/56Mbps), 4K(H265: 3840×1920/29.97fps/32Mbps), 2K(H264: 1920×960/29.97fps/16Mbps), 2K(H265: 1920×960/29.97fps/8Mbps)",
      "Internal memory: Approx. 19GB",
      "Effective pixels: Approx. 12 megapixels (x2)",
      "Shooting mode: Still image(auto, shutter priority, ISO priority, manual), video(auto), live streaming(auto)",
      "Battery life: Still image - approx. 300 photos, video - approx. 80 minutes",
    ],
  },
  {
    img: camera3,
    title: "Aleta S2C 360",
    linkWebsite: "https://www.ultracker.com/",
    linkAmazon: "https://www.amazon.com/Aleta-S2C-Camcorder-Standard-Batteries/dp/B07F1HHM8B",
    info: [
      "Photo Capture: 12K ultra high resolution 360 still photo, 66MP (11520 x 5760) high resolution 360 panoramic photo",
      "Video Capture Resolution: Time-lapse 7680 x 3840",
      "Shooting Modes: Time-Lapse, Low Light, DAY, NIGHT, Sport, Manual. AEB, Dynamic Object Removal.",
      "Max Focal Length: 2.5 Millimeters",
      "Ideal for Google Street View (OSC support), virtual tours: real estate, construction site, interior design, architecture, engineering, archaeology, 3D modeling (with Laser scanner), travel, VR/AR content, standalone 360 live streaming",
    ],
  },
  {
    img: camera4,
    title: "Insta360 ONE X",
    linkWebsite: "https://www.insta360.com/product/insta360-onex",
    linkAmazon: "https://www.amazon.com/Insta360-Flowstate-Stabilization-Independently-MicroSDXC/dp/B07GXBYYWN",
    info: [
      "Aperture: F2.0",
      "Photo resolution: 18 MP (6080*3040)",
      "Video resolution:  5760*2880@30fps, 5760*2880@25fps, 5760*2880@24fps, 3840*1920@50fps, 3840*1920@30fps, 3008*1504@100fps",
      "Stabilization: Built-in 6-axis gyroscopic stabilization",
      "Compatible devices: iPhone 11/11 Pro/11 Pro Max, iPhone XS/XS Max, iPhone XR, iPhone X, iPhone 8/8 Plus, iPhone 7/7 Plus, iPhone 6s/6s Plus, iPhone SE iPad Pro, iPad (2018)",
      "Run Time: ONE X lasts roughly 60 mins while shooting 5.7K @30FPS or 4K@50FPS video when it is used independently",
    ],
  },
  {
    img: camera5,
    title: "Insta360 ONE / Insta360 Camera",
    linkWebsite: "https://www.insta360.com/product/insta360-one",
    linkAmazon: "https://www.amazon.com/Insta360-ONE-Action-FlowState-Stabilization/dp/B074VW233J",
    info: [
      "Aperture: F2.2",
      "Photo resolution: 24 MP (6912*3456)",
      "Video resolution: 3840*1920@30fps, 2560*1280@60fps, 2048*512@120fps(bullet time)",
      "Stabilization: Built-in 6-axis gyroscopic stabilization",
      "Compatible devices: iPhone XS, iPhone XS Max, iPhone XR, iPhone X, iPhone8/8 plus, iPhone 7/7 plus, iPhone 6s/6s plus, iPhone 6/6 plus, iPhone SE",
      "iPad Pro(10.5-inch), iPad Pro(9.7-inch), iPad (9.7-inch), iPad Pro(12.9-inch), iPad mini4, iPad Air 2",
      "Endurance: 70 min (continuous video shooting)",
    ],
  },
];

const HeroBanner = () => {
  const isMobile = checkMobile(767);

  return (
    <div
      id="hero"
      className="hero_banner hero-camera"
      style={{ backgroundImage: `url(${isMobile ? heroMobile : heroDesktop})` }}
    >
      <div className="is-container">
        <h1 className="heading_h1">Compatible Camera Equipment</h1>
        <h2 className="heading_h2">
          View the full list of possible camera equipment to be used with Skyward mobile app. In addition to that you
          will be able to upload photos right from your phone.
        </h2>

        <a href={APP_STORE_LINK} className="appStoreButton">
          <img src={appStore} alt="" />
        </a>
      </div>
    </div>
  );
};

const Cameras = () => {
  useScrollTopOnHistory();

  return (
    <div>
      <HeaderMain homepage />
      <HeroBanner />

      <div className="is-container">
        <div className="camera-items">
          {cameras.map(item => {
            return (
              <CameraItem key={v4()} {...item}>
                {item.info.map(info => (
                  <p key={v4()}>{info}</p>
                ))}
              </CameraItem>
            );
          })}
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Cameras;
