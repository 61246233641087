import React, { useState } from "react";
import ModalEnterpisePricing from "../modals/ModalEnterpisePricing";

const EnterpriseBanner = () => {
  const [shownModal, setShownModal] = useState(false);
  const toggleModal = () => setShownModal(!shownModal);

  return (
    <>
      <div className="enterprise-banner">
        <div>
          <div className="enterprise-banner__title">Enterprise</div>
          <div className="enterprise-banner__text">
            Discount rates for multiple plans. Additional customization and personal company branding.
          </div>
        </div>

        <button type="button" className="custom_button" onClick={toggleModal}>
          CONTACT FOR PRIСING
        </button>
      </div>

      {shownModal && <ModalEnterpisePricing onToggle={toggleModal} />}
    </>
  );
};

export default EnterpriseBanner;
