import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import ModalSubscription from "../modals/ModalSubscription";

import addressIcon from "../../assets/img/icons/icon-address-card.svg";
import ModalPurchasePlan from "../modals/ModalPurchasePlan";
import { resetUserPlanFields } from "../../redux/actions/recurlyActions";
import ModalPlanUpdated from "../modals/ModalPlanUpdated";
import { capitalize } from "../../utils";
import ModalCancelSubscription from "../modals/ModalCancelSubscription";
import useSubscriptionType from "../../hooks/useSubscriptionType";

const SubscriptionLevel = () => {
  const dispatch = useDispatch();

  const [shownSubModal, setShownSubModal] = useState(false);
  const [selectedSub, setSelectedSub] = useState(null);
  const { type, pendingChange, currentPeriodEndsAt, isCanceled } = useSubscriptionType();

  const updatingPlanSuccess = useSelector(state => state.recurly.updatingPlanSuccess);

  let subName = "loading...";
  if (type) {
    subName = type;
  }
  const toggleSubModal = () => {
    setShownSubModal(value => !value);
  };
  const togglePurchaseModal = () => {
    setSelectedSub(null);
  };

  const setPlanId = id => {
    setSelectedSub(id);
    setShownSubModal(false);
  };

  const onCloseSuccessModal = () => {
    setSelectedSub(null);
    resetUserPlanFields(dispatch);
  };

  const detailsText = useMemo(() => {
    if (pendingChange) {
      return `Your ${pendingChange.plan.name.toUpperCase()} plan should start on ${moment(currentPeriodEndsAt).format(
        "DD/MM/YYYY"
      )}`;
    }
    if (isCanceled) {
      return `Your FREE plan should start on ${moment(currentPeriodEndsAt).format("DD/MM/YYYY")}`;
    }

    return `Your current subscription will renew on ${moment(currentPeriodEndsAt).format("DD/MM/YYYY")}`;
  }, [currentPeriodEndsAt, pendingChange, isCanceled]);

  return (
    <>
      <section className="settings-subscription__row">
        <div className="two-lines">
          <div className="settings-form__title">
            <img src={addressIcon} alt="" />
            Subscription Level - {capitalize(subName)}
          </div>
          {currentPeriodEndsAt && <p className="subscription-date-info">{detailsText}</p>}
        </div>

        <button className="custom_button__light custom_button-sm-width" onClick={toggleSubModal} type="button">
          Change
        </button>
      </section>

      {shownSubModal && <ModalSubscription onPress={setPlanId} onToggle={toggleSubModal} />}
      {selectedSub && selectedSub !== "01" && selectedSub !== "02" && updatingPlanSuccess !== true && (
        <ModalPurchasePlan id={selectedSub} onToggle={togglePurchaseModal} />
      )}
      {(selectedSub === "01" || selectedSub === "02") && <ModalCancelSubscription onToggle={onCloseSuccessModal} />}
      {updatingPlanSuccess === true && <ModalPlanUpdated onToggle={onCloseSuccessModal} />}
    </>
  );
};

export default SubscriptionLevel;
