import React, { useReducer, useMemo, useEffect, useCallback } from "react";
import { useSelector, useDispatch as useReduxDispatch } from "react-redux";
import * as PropTypes from "prop-types";
import alert from "../assets/img/icons/icon-alert.svg";

import {
  loadBillingInfo,
  resetBillingInfoError,
  resetUserPlanFields,
  updateUserPlan,
} from "../redux/actions/recurlyActions";
import iconCard from "../assets/img/icons/icon-card.svg";
import iconCvv from "../assets/img/icons/icon-cvv.svg";
import iconDate from "../assets/img/icons/icon-date.svg";

const ccNumberPattern = /^\d{0,16}$/g;
const ccNumberSeparator = " ";
const ccExpiryPattern = /^\d{0,4}$/g;
const ccExpirySeparator = "/";
const ccCvvPattern = /^\d{0,3}$/g;

const initialState = {
  card: "",
  cvv: "",
  date: "",
};

const ACTION_TYPES = {
  SET_CARD: "SET_CARD",
  SET_CVV: "SET_CVV",
  SET_DATE: "SET_DATE",
  RESET: "RESET",
};

const reducer = (state, action) => {
  switch (action.type) {
    case ACTION_TYPES.SET_CARD:
      return {
        ...state,
        card: action.payload,
      };
    case ACTION_TYPES.SET_CVV:
      return {
        ...state,
        cvv: action.payload,
      };
    case ACTION_TYPES.SET_DATE:
      return {
        ...state,
        date: action.payload,
      };
    case ACTION_TYPES.RESET:
      return initialState;
    default:
      throw new Error();
  }
};

const mask = (value, limit, separator) => {
  const output = [];
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < value.length; i++) {
    if (i !== 0 && i % limit === 0) {
      output.push(separator);
    }

    output.push(value[i]);
  }

  return output.join("");
};

const unmask = value => value.replace(/[^\d]/g, "");

const CreditCard = ({ price, id, currency }) => {
  let cardPlaceholder = "xxxx xxxx xxxx xxxx";
  let datePlaceholder = "MM/YY";
  let cvvPlaceholder = "CVV";

  let ccNumberInputOldValue;
  let ccExpiryInputOldValue;

  const reduxDispatch = useReduxDispatch();
  const billingInfo = useSelector(state => state.recurly.billingInfo);
  const updatingPlanErrorText = useSelector(state => state.recurly.updatingPlanErrorText);
  const updatingPlanIsLoaded = useSelector(state => state.recurly.updatingPlanIsLoaded);
  const [state, dispatch] = useReducer(reducer, initialState);

  if (billingInfo) {
    const card = `${billingInfo.firstSix}******${billingInfo.lastFour}`;
    const year = `${billingInfo.expYear}`.slice(-2);
    const month = billingInfo.expMonth;
    cardPlaceholder = mask(card, 4, ccNumberSeparator);
    datePlaceholder = `${month}/${year}`;
    cvvPlaceholder = "***";
  }

  useEffect(() => {
    loadBillingInfo(reduxDispatch);
  }, [reduxDispatch]);

  const onResetErrorAlert = useCallback(() => {
    if (updatingPlanErrorText) {
      resetBillingInfoError(reduxDispatch);
      resetUserPlanFields(reduxDispatch);
    }
  }, [reduxDispatch, updatingPlanErrorText]);

  const onSuccess = () => {
    dispatch({ type: ACTION_TYPES.RESET });
  };

  const onError = () => {
    dispatch({ type: ACTION_TYPES.RESET });
  };

  const ccNumberInputKeyDownHandler = e => {
    const el = e.currentTarget;
    ccNumberInputOldValue = el.value;
  };
  const ccNumberInputInputHandler = e => {
    onResetErrorAlert();
    const el = e.currentTarget;
    let newValue = unmask(el.value);

    if (newValue.match(ccNumberPattern)) {
      newValue = mask(newValue, 4, ccNumberSeparator);

      const result = newValue !== "" ? newValue : "";
      dispatch({ type: ACTION_TYPES.SET_CARD, payload: result });
    } else {
      dispatch({ type: ACTION_TYPES.SET_CARD, payload: ccNumberInputOldValue });
    }
  };

  const ccExpiryInputKeyDownHandler = e => {
    const el = e.target;
    ccExpiryInputOldValue = el.value;
  };
  const ccExpiryInputInputHandler = e => {
    onResetErrorAlert();

    const el = e.currentTarget;
    let newValue = el.value;

    newValue = unmask(newValue);
    if (newValue.match(ccExpiryPattern)) {
      newValue = mask(newValue, 2, ccExpirySeparator);
      dispatch({ type: ACTION_TYPES.SET_DATE, payload: newValue });
    } else {
      dispatch({ type: ACTION_TYPES.SET_DATE, payload: ccExpiryInputOldValue });
    }
  };

  const ccCvvInputHandler = e => {
    onResetErrorAlert();

    const el = e.currentTarget;
    const newValue = el.value;
    if (newValue.match(ccCvvPattern)) {
      dispatch({ type: ACTION_TYPES.SET_CVV, payload: newValue });
    }
  };

  useEffect(() => {
    return () => {
      resetBillingInfoError(reduxDispatch);
    };
  }, [reduxDispatch]);

  const buttonText = useMemo(() => {
    if (updatingPlanIsLoaded) {
      return "Updating.....";
    }

    return `PURCHASE FOR ${price}`;
  }, [updatingPlanIsLoaded, price]);

  const dateError = useMemo(() => {
    const currentYear = new Date().getFullYear().toString().slice(-2);

    let month = state.date.split("/")[0];
    let year = state.date.split("/")[1];
    month = month && month.length === 2 ? Number(month) : undefined;
    year = year && year.length === 2 ? Number(year) : undefined;

    let monthError = "";
    let yearError = "";

    if (month && (month > 12 || month < 0)) {
      monthError = "Month shoud be less then 13 and more then 0. ";
    }
    if (year && year < currentYear) {
      yearError = "Year shoud be more then today. ";
    }

    return monthError + yearError;
  }, [state.date]);

  const mainError = useMemo(() => {
    let result = "";
    if (dateError) {
      result += dateError;
    }
    if (updatingPlanErrorText) {
      result += updatingPlanErrorText;
    }

    return result;
  }, [dateError, updatingPlanErrorText]);

  const isFilledInputs =
    state.card.length === 19 && state.date.length === 5 && state.cvv.length === 3 && !dateError.length;
  const alreadyHaveBillingInfo =
    billingInfo && state.card.length === 0 && state.date.length === 0 && state.cvv.length === 0;
  const canClickSubmit = isFilledInputs || alreadyHaveBillingInfo;

  const onSubmit = () => {
    const billing = isFilledInputs
      ? {
          firstName: "unknown",
          lastName: "unknown",
          number: state.card,
          month: state.date.split("/")[0],
          year: `20${state.date.split("/")[1]}`,
          cvv: state.cvv,
        }
      : undefined;
    const data = {
      body: {
        planId: id,
        currency,
      },
      billingInfo: billing,
    };
    updateUserPlan(reduxDispatch, data, onError, onSuccess);
  };

  return (
    <div className="credit-card">
      <div className={`credit-card-error-wrapper ${mainError ? "is-active" : ""}`}>
        <div className="credit-card-error">
          <img src={alert} alt="" />
          <p>{typeof mainError === "string" ? mainError : "Something went wrong"}</p>
        </div>
      </div>

      <div className="text-field">
        <img src={iconCard} alt="" />
        <input
          name="ccn"
          id="ccn"
          type="tel"
          inputMode="numeric"
          autoComplete="cc-number"
          maxLength="19"
          placeholder={cardPlaceholder}
          value={state.card}
          onChange={ccNumberInputInputHandler}
          onKeyDown={ccNumberInputKeyDownHandler}
        />
      </div>
      <div className="credit-card__row">
        <div className="text-field">
          <img align="middle" src={iconDate} alt="" />
          <input
            name="ccd"
            id="ccd"
            type="tel"
            inputMode="numeric"
            autoComplete="cc-number"
            maxLength="5"
            placeholder={datePlaceholder}
            value={state.date}
            onChange={ccExpiryInputInputHandler}
            onKeyDown={ccExpiryInputKeyDownHandler}
            className={`${dateError.length ? "is-input-error" : ""}`}
          />
        </div>
        <div className="separator" />
        <div className="text-field">
          <img src={iconCvv} alt="" />

          <input
            name="ccc"
            id="ccc"
            type="tel"
            inputMode="numeric"
            autoComplete="cc-number"
            maxLength="3"
            placeholder={cvvPlaceholder}
            value={state.cvv}
            onChange={ccCvvInputHandler}
          />
        </div>
      </div>
      <p className="modal-purchase-plan__body__notification">This card will be used in your next payments</p>
      <button onClick={onSubmit} disabled={!canClickSubmit} className="custom_button__light" type="submit">
        {buttonText}
      </button>
    </div>
  );
};

CreditCard.propTypes = {
  id: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
};

export default CreditCard;
