import React, { useEffect } from "react";
import * as PropTypes from "prop-types";

import { useDispatch } from "react-redux";

import {
  FREE_SUBSCRIPTION_PLAN,
  LITE_SUBSCRIPTION_PLAN,
  UNLIMITED_SUBSCRIPTION_PLAN,
} from "../../constants/subscriptions";
import { loadCurrentSubscriptions } from "../../redux/actions/recurlyActions";
import useSubscriptionType from "../../hooks/useSubscriptionType";
import { formatPrice } from "../../utils";

const SubscriptionItem = ({ id, type, price, options, monthly, onPress, currency }) => {
  const dispatch = useDispatch();
  const { type: subscriptionType, currentSubscriptionMonthly } = useSubscriptionType();
  const formatedPrice = formatPrice(price, currency);
  useEffect(() => {
    loadCurrentSubscriptions(dispatch);
  }, [dispatch]);

  let isActiveSub = false;

  if (subscriptionType === type && type === FREE_SUBSCRIPTION_PLAN.type) {
    isActiveSub = true;
  } else if (
    subscriptionType === type &&
    ((monthly && currentSubscriptionMonthly) || (!monthly && !currentSubscriptionMonthly))
  ) {
    isActiveSub = true;
  }

  const onPaymentAddMethodClick = () => {
    onPress(id);
  };

  const renderColor = () => {
    if (type === FREE_SUBSCRIPTION_PLAN.type) {
      return "subscription-grey";
    }
    if (type === LITE_SUBSCRIPTION_PLAN.type) {
      return "subscription-medium";
    }
    if (type === UNLIMITED_SUBSCRIPTION_PLAN.type) {
      return "subscription-green";
    }

    return "subscription-grey";
  };

  return (
    <>
      <div className={`subscription-item ${renderColor()}`}>
        <p className="subscription-type">{type}</p>
        <p className="subscription-price subscription-price__month">
          {`${formatedPrice}/${monthly ? "month" : "year"}`}
        </p>

        <ul className="subscription-options">
          {options.map(option => (
            <li key={option}>{option}</li>
          ))}
        </ul>

        {isActiveSub ? (
          <p className="subscription-item__alert">You are already using this plan</p>
        ) : (
          <button className="custom_button" type="button" onClick={onPaymentAddMethodClick}>
            select {type} plan
          </button>
        )}
      </div>
    </>
  );
};

SubscriptionItem.defaultProps = {
  monthly: true,
  onPress: () => null,
};

SubscriptionItem.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  monthly: PropTypes.bool,
  onPress: PropTypes.func,
};

export default SubscriptionItem;
