import React, { useState } from "react";
import * as PropTypes from "prop-types";

import { useDispatch, useSelector } from "react-redux";
import Modal from "../Modal";

import iconCancel from "../../assets/img/icons/icon-cancel-modal.svg";
import { resetUserPlanFields } from "../../redux/actions/recurlyActions";
import ModalSubscription from "./ModalSubscription";
import ModalPurchasePlan from "./ModalPurchasePlan";
import ModalCancelSubscription from "./ModalCancelSubscription";
import ModalPlanUpdated from "./ModalPlanUpdated";
import useSubscriptionType from "../../hooks/useSubscriptionType";

const ModalNumberToursLimited = ({ onToggle }) => {
  const dispatch = useDispatch();
  const [selectedSub, setSelectedSub] = useState(null);
  const [shownSubModal, setShownSubModal] = useState(false);
  const { limite } = useSubscriptionType();
  const updatingPlanSuccess = useSelector(state => state.recurly.updatingPlanSuccess);

  const toggleSubModal = () => {
    setShownSubModal(value => !value);
  };
  const togglePurchaseModal = () => {
    setSelectedSub(null);
  };

  const setPlanId = id => {
    setSelectedSub(id);
    setShownSubModal(false);
  };

  const onCloseSuccessModal = () => {
    onToggle();
    setSelectedSub(null);
    resetUserPlanFields(dispatch);
  };

  const onUpgrade = () => {
    setShownSubModal(true);
  };

  return (
    <>
      <Modal onToggle={onToggle} title="Number of tours are limited" size="sm">
        <div className="modal-default">
          <img src={iconCancel} className="modal-default__icon" alt="" />

          <p>
            {`Maximum number of tours reached (${limite}). Upgrade to `}
            <br />
            {`have ${limite} and more tours in your cabinet`}
          </p>

          <button type="button" className="custom_button__light custom_button-sm-width" onClick={onUpgrade}>
            Upgrade
          </button>
        </div>
      </Modal>

      {shownSubModal && <ModalSubscription onPress={setPlanId} onToggle={toggleSubModal} />}
      {selectedSub && selectedSub !== "01" && selectedSub !== "02" && updatingPlanSuccess !== true && (
        <ModalPurchasePlan id={selectedSub} onToggle={togglePurchaseModal} />
      )}
      {(selectedSub === "01" || selectedSub === "02") && <ModalCancelSubscription onToggle={onCloseSuccessModal} />}
      {updatingPlanSuccess === true && <ModalPlanUpdated onToggle={onCloseSuccessModal} />}
    </>
  );
};

ModalNumberToursLimited.propTypes = {
  onToggle: PropTypes.func.isRequired,
};

export default ModalNumberToursLimited;
