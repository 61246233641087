import React, { useEffect, useMemo } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";

import { useSelector, useDispatch } from "react-redux";
import HeaderMain from "../components/HeaderMain";
import Footer from "../components/Footer";
import ArrowScrollTop from "../components/ArrowScrollTop";
import Pricing from "../components/Landing/Pricing";

import hero from "../assets/img/landing/hero.jpg";
import living from "../assets/img/landing/living-room.jpg";
import stepIntoLiving from "../assets/img/landing/step-into-living-room.jpg";
import screen from "../assets/img/landing/screen.png";
import phoneIcons from "../assets/img/icons/icon-devices.svg";
import appIcon from "../assets/img/icons/icon-app.svg";
import phone from "../assets/img/landing/phone.png";
import appStore from "../assets/img/landing/app-store-button.svg";

import checkMobile from "../hooks/checkMobile";
import SubscriptionItem from "../components/Landing/SubscriptionItem";
import { loadSubscriptions } from "../redux/actions/recurlyActions";
import useScrollTopOnHistory from "../hooks/useScrollTopOnHistory";
import { APP_STORE_LINK } from "../constants/shared";

const Landing = () => {
  const isMobile = checkMobile();

  const dispatch = useDispatch();
  const subscriptions = useSelector(state => state.recurly.subscriptions);

  const subscriptionPlans = useMemo(() => {
    return subscriptions.filter(item => item.interval === 1);
  }, [subscriptions]);

  useScrollTopOnHistory();

  useEffect(() => {
    loadSubscriptions(dispatch);
  }, [dispatch]);

  const HeroBanner = () => (
    <div id="hero" className="hero_banner" style={{ backgroundImage: `url(${hero})` }}>
      <div className="is-container">
        <h1 className="heading_h1">Skyward 360 Tours</h1>
        <h2 className="heading_h2">
          Launch your business into the future with ultra-high definition 3D tours. Welcome to the future of real
          estate.
        </h2>

        <AnchorLink className="custom_button" href="#howItWorks" offset={isMobile ? 60 : 80}>
          See how it works
        </AnchorLink>
      </div>
    </div>
  );

  const AboutUs = () => (
    <div id="about" className="is-block">
      <div className="is-container">
        <div className="heading-border">
          <h2>Why Skyward</h2>
          <p>
            Designed to affordably streamline the home showing process, Skyward 360 Tours elevates your work by allowing
            you to create quick and easy virtual tours of your listings. This gives prospective tenants and buyers an
            immersive showing experience prior to ever scheduling a showing. This translates to a higher level of trust
            with your clients and ultimately saves you time and money with more deals closed on fewer showings.
          </p>
        </div>

        <div className="is-block-row">
          <div className="is-block-info">
            <img src={phoneIcons} alt="" />
            <p className="is-block-info__title">
              Fast, user-friendly,
              <br /> and responsive
            </p>
            <p className="is-block-info__text">
              Create listings with just a few clicks and share them instantly to clients via SMS, social media, or
              email, with unique URLs for each address.
            </p>
          </div>

          <div className="is-block-img">
            <img src={stepIntoLiving} alt="" />
          </div>
        </div>

        <div className="is-block-row is-block-row__reversed">
          <div className="is-block-img">
            <img src={living} alt="" />
          </div>

          <div className="is-block-info">
            <img src={appIcon} alt="" />
            <p className="is-block-info__title">The most accessible home tour app on the market</p>
            <p className="is-block-info__text">
              Our app is compatible with the Theta V and Z1, as well as panoramas created on your iPhone or iPad.
            </p>
          </div>
        </div>
      </div>
    </div>
  );

  const AccessibleHome = () => (
    <div id="howItWorks" className="is-block is-block-gradient">
      <div className="is-container">
        <div className="heading-border">
          <h2>How it Works</h2>

          <ul className="subscription-options how-it-works">
            <li>Our app wirelessly connects to Theta V and Z1 cameras and creates VR tours in a flash!</li>

            <li>
              No 3D camera? No problem. Our app works seamlessly with panoramic photos taken on your smartphone, making
              it easy for anyone to create tours with no extra equipment.
            </li>
            <li>Share personally branded listings instantly through email, SMS, or anywhere else via unique URLs.</li>
          </ul>
        </div>

        <img className="is-image" src={screen} alt="" />
      </div>
    </div>
  );

  const AppStoreBanner = () => (
    <div className="appStoreBanner">
      <div className="is-container">
        <h2>
          Skyward 360 <br />
          Tours App
        </h2>

        <p>
          Skyward 360 Tours is designed by real estate agents, for real estate agents, to make your life easier and your
          workday more efficient.
        </p>

        <a href={APP_STORE_LINK} className="appStoreButton">
          <img src={appStore} alt="" />
        </a>

        <img className="phone-image" src={phone} alt="" />
      </div>
    </div>
  );

  return (
    <>
      <HeaderMain homepage />
      <HeroBanner />
      <AboutUs />
      <AccessibleHome />
      <Pricing>
        <div className="subscriptions-wrapper">
          {subscriptionPlans &&
            subscriptionPlans.map(item => (
              <SubscriptionItem
                key={item.id}
                id={item.id}
                type={item.type}
                currency={item.currencies[0].currency}
                price={item.currencies[0].unitAmount}
                options={item.options}
              />
            ))}
        </div>
      </Pricing>
      <AppStoreBanner />
      <Footer />
      <ArrowScrollTop />
    </>
  );
};

export default Landing;
